/* -------------------------------------------------------------------------- */
/*                                   ProximaNova                               */
/* -------------------------------------------------------------------------- */

@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('../assets/fonts/ProximaNova/ProximaNova-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* -------------------------------------------------------------------------- */
/*                                   BalsamiqSans                                 */
/* -------------------------------------------------------------------------- */


@font-face {
  font-family: 'Balsamiq Sans Regular';
  src: url('../assets/fonts/BalsamiqSans/BalsamiqSans-Regular.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Balsamiq Sans Bold';
  src: url('../assets/fonts/BalsamiqSans/BalsamiqSans-Bold.otf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Balsamiq Sans Italic';
  src: url('../assets/fonts/BalsamiqSans/BalsamiqSans-Italic.otf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Balsamiq Sans Bold Italic';
  src: url('../assets/fonts/BalsamiqSans/BalsamiqSans-BoldItalic.otf') format('truetype');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

/* -------------------------------------------------------------------------- */
/*                                   Montserrat                               */
/* -------------------------------------------------------------------------- */

@font-face {
  font-family: 'Montserrat Bold';
  src: url('../assets/fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Regular';
  src: url('../assets/fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat Semi Bold';
  src: url('../assets/fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* -------------------------------------------------------------------------- */
/*                                   Alata                               */
/* -------------------------------------------------------------------------- */

@font-face {
  font-family: 'Alata';
  src: url('../assets/fonts/Alata/Alata-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* -------------------------------------------------------------------------- */
/*                                   Nunito Sans                               */
/* -------------------------------------------------------------------------- */

@font-face {
  font-family: 'NunitoSans ExtraBold';
  src: url('../assets/fonts/NunitoSans/NunitoSans-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans Bold';
  src: url('../assets/fonts/NunitoSans/NunitoSans-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'NunitoSans Regular';
  src: url('../assets/fonts/NunitoSans/NunitoSans-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
